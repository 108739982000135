<template>
   <!-- HISTORY:
      V240830.1: Made Menu conditional for dashboards embeding purposes.
      V230906.1: Added logic to created() to highlight the landing page (dashboards).
      10/14/20(B0.1): File was created.
   -->

   <div>
      <v-content class="px-0 pt-0 pb-0">
         <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
            <span>{{ this.snackbarMsg }}</span>
            <v-btn text color="white" @click="snackbar = false">Close</v-btn>
         </v-snackbar>

         <v-layout>
            <Menu v-if="showMenu"
               :baseRoute="baseRoute"
               :mini-variant="miniVariant"
               @more-click="moreClicked"
            />
            <router-view 
               :debug="true"
               :is-actual-endpoint="true"
               @snackbar-event="setSnackbar"
            ></router-view>
         </v-layout>
      </v-content>
   </div>
</template>

<script>
import Menu from '../components/CsMenu.vue';
import { IamSettings } from '../models/iam-settings.js';

export default {
   components: {
      Menu
   },

   data() {
      return {
         baseRoute: "contact-service",
         snackbar: false,
         snackbarMsg: '',
         settings: new IamSettings(),
         showMenu: true
      }
   },

   computed: {
      miniVariant() {
         return !this.settings.keepMenuExpanded;
      }
   },

   methods: {
      setSnackbar (msg) {
         this.snackbarMsg = msg;
         this.snackbar = true;
      },

      moreClicked() {
         this.settings.keepMenuExpanded = !this.settings.keepMenuExpanded;
         this.settings.save();
      }
   },

   created() {
      // // this.$router.push(`/${this.baseRoute}/dashboards`);
      // alert(this.$route.query.token);
      //alert(JSON.stringify(this.$route.query))
      if (this.$route?.query?.token)
         this.showMenu = false;
   }
};
</script>
